
import { Vue, Component, Prop } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import LynusChartBasicView from '@/ui/components/devices/charts/charts/LynusChart.vue';
import DataExportNew from '@/ui/components/devices/charts/charts/DataExportNew.vue';
import DeviceActions from '@/ui/components/devices/actions/DeviceActions.vue';
import TotalValuesWindow from '@/ui/components/devices/charts/components/TotalValuesWindow.vue';
import { Periods, getChartOptions, periodConfigurations } from '@/ui/components/devices/charts/charts/ChartUtils';
import _ from 'lodash';
import { Chart, PointerEventObject } from 'highcharts/highstock';
import CalendarNavigation from '@/ui/components/devices/charts/charts/CalendarNavigation.vue';
import { ChartData, ApproximationValue } from '@/types/chart.types';

@Component({
  components: {
    CalendarNavigation,
    LynusChartBasicView,
    DataExportNew,
    DeviceActions,
    TotalValuesWindow,
  },
})
export default class ComparisonCharts extends Vue {
  @Prop() chartData!: ChartData;
  @Prop({ default: 400 }) chartHeight!: number;
  @Prop({ default: true }) showDeviceActions!: boolean;
  @Prop({ default: false }) isThresholdLine!: boolean;
  @Prop({ default: () => ({ day: 4, week: 1, month: 1, year: 1 }) }) customApproximationValue!: ApproximationValue;
  @Getter('projects/projectStartedAtNumber') projectStartedAtNumber!: number;

  readonly multiSelectLimit: number = 4;
  // variable used to control how many charts will be rendered
  selectedPeriodCount = 1;
  navigationItemsToExclude = ['live'];

  currentPeriod: string = Periods.DAY;
  reRenderKey = 0;

  overflow: any = 'hidden';
  disableNavButton: any = false;
  dataToExport: any = null;
  isStackingButtonActive = true
  differenceSeriesStats: any = []
  otherSeriesStats: any = []
  localLegendItems = [];
  seriesSuffixes: any = []

  chartVisibleArray = [true, true, true, true]
  get chartOptions() {
    return getChartOptions(this.chartData);
  }

  get numberOfColumns() {
    const optionsList = this.chartOptions.filter((el: any) => ['column', 'diff'].includes(el.type));
    return optionsList.length;
  }
  get numberOfLines() {
    const optionsList = this.chartOptions.filter((el: any) => el.type === 'line');
    return optionsList.length;
  }

  /**
   * Callback for mouse move inside chart.
   */
  onMouseMove(chart: Chart, event: PointerEventObject) {
    this.sync(chart, event);
  }

  /**
   * Callback for mouse leaving chart div.
   */
  onMouseLeave() {
    this.highchartInstances.forEach((chart) => {
      chart.series.forEach((series) => {
        series.data.forEach((point) => point.setState());
      });

      chart.tooltip.hide();
      chart.xAxis[0].hideCrosshair();
    });
  }

  /**
   * Synchronizes all charts' tooltips, points and crosshairs to the currently hovered chart,
   * according to the given mouse move event.
   */
  sync(currentChart: Chart, event: PointerEventObject) {
    event = currentChart.pointer.normalize(event);

    this.highchartInstances.forEach((chart) => {
      // skip current chart, as mouse is already there
      if (chart === currentChart) return;
      let emptyChecker = true;
      chart.series.forEach((series) => {
        if (series.data.length !== 0) {
          emptyChecker = false;
        }
      });
      if (!emptyChecker) {
        chart.series.forEach((series) => {
          const point = series.searchPoint(event, true);
          const points: any[] = [];
          // loads the points of all series for the tooltip to update correctly
          chart.series.forEach(singleSeries => {
            points.push(singleSeries.searchPoint(event, true));
          });
          if (point) {
            point.setState('hover');
            if (chart.tooltip?.refresh !== undefined && chart.xAxis.length !== 0) {
              chart.tooltip.refresh(points);
              chart.xAxis[0].drawCrosshair(event, point);
            }
          }
        });
      }
    });
  }

  /**
   * Returns the highchart instances of the single charts that should be compared.
   */
  get highchartInstances(): Chart[] {
    return (this.$refs.charts as []).map((c: LynusChartBasicView) => c.getChartObject().chart);
  }
  /**
   * Returns the lynuschart instances. With all the functions to use.
   */
  get lynusChartInstances() {
    return (this.$refs.charts as []).map((c: LynusChartBasicView) => c);
  }

  /**
   * Behaves like python `range`.
   */
  range(a: number) {
    return _.range(a);
  }

  /**
   * Defines Chart Stats of Multiselect Charts. Those stats will be visible inside the Statswindow.
   */
  defineSeriesStatsMultiSelection() {
    this.differenceSeriesStats = [];
    this.otherSeriesStats = [];
    this.lynusChartInstances.forEach((chartInstance: any, chartIndex: number) => {
      if (this.chartVisibleArray[chartIndex] === true) {
        const chartOptions = chartInstance;
        chartOptions.chart.chart.series.forEach((seriesElement: any, seriesIndex: any) => {
          const currentChartOptionIndex = seriesIndex % this.chartOptions.length;
          let valuesArray;
          let seriesName = chartOptions.chart.chart.legend.allItems[seriesIndex].name;
          if (this.chartOptions[currentChartOptionIndex].agg === 'diff') {
            valuesArray = this.getValuesOfSeries(seriesIndex, this.chartOptions[currentChartOptionIndex].type, chartOptions.getChartObject());

            const totalValue = _.sum(valuesArray) ?? 0;
            seriesName = this.getSeriesName(currentChartOptionIndex, chartIndex);

            this.differenceSeriesStats.push({ name: seriesName, sum: totalValue.toFixed(2), unit: this.chartOptions[currentChartOptionIndex].unit });
          } else {
            valuesArray = this.getValuesOfSeries(seriesIndex, this.chartOptions[currentChartOptionIndex].type, chartOptions.getChartObject());

            const max: number = _.max(valuesArray) ?? 0;
            const min: number = _.min(valuesArray) ?? 0;
            const avg: number = _.mean(valuesArray) ?? 0; // return avg of all numbers in array: https://www.geeksforgeeks.org/lodash-_-mean-method/

            seriesName = this.getSeriesName(currentChartOptionIndex, chartIndex);
            this.otherSeriesStats.push({ name: seriesName, minValue: min.toFixed(2), maxValue: max.toFixed(2), avgValue: avg.toFixed(2), unit: this.chartOptions[currentChartOptionIndex].unit });
          }
        });
      }
    });
  }

  getSeriesName(seriesIndex: number, chartIndex: number) {
    let seriesName = '';
    const currentChartBounds = this.lynusChartInstances[chartIndex].chart.chartBounds;
    const concatString = this.convertTimestampChartStats(currentChartBounds);
    if (this.chartOptions[seriesIndex].name !== '') {
      seriesName = `${this.chartOptions[seriesIndex].name}_${concatString}`;
    } else {
      seriesName = `${this.chartOptions[seriesIndex].seriesType} ${seriesIndex}_${concatString}`;
    }
    return seriesName;
  }
  /**
   * Converts Unix timestamp into for example 'dd.mm.yyyy' based on the period selected
   */
  convertTimestampChartStats(givenBounds: any) {
    let dateString = '';
    const dStart = new Date(givenBounds.start * 1000);
    const dEnd = new Date(givenBounds.endChart * 1000);
    if (this.currentPeriod === 'day') {
      dateString = `${dStart.getDate()}.${dStart.getMonth() + 1}.${dStart.getFullYear()}`;
    } else if (this.currentPeriod === 'week') {
      dateString = `${dStart.getDate()}.${dStart.getMonth() + 1}.${dStart.getFullYear()} - ${dEnd.getDate()}.${dEnd.getMonth() + 1}.${dEnd.getFullYear()}`;
    } else if (this.currentPeriod === 'month') {
      dateString = `${dStart.getMonth() + 1}.${dStart.getFullYear()}`;
    } else if (this.currentPeriod === 'year') {
      dateString = `${dStart.getFullYear()}`;
    } else if (this.currentPeriod === 'hour') {
      dateString = `${dStart.getDate()}.${dStart.getMonth() + 1}.${dStart.getFullYear()} ${dStart.getHours()}:${dStart.getMinutes()}`;
    }
    return dateString;
  }

  /**
   * Loads all the data from one series for Chart Stats
   */
  getValuesOfSeries(index: number, type: string, chartOptions: any) {
    const chart = chartOptions;
    if (type !== 'column') {
      return chart.chart.series[index].data.map((dataElement: any) => {
        return dataElement.options.y;
      });
    } else {
      return (chart.chart.series[index] as any).yData;
    }
  }

  /**
   * Function loads the current Period in click of Chart Navigation Buttons
   */
  handleNavigation(period: string) {
    this.handlePeriodsForAutarkiegrad(period);
    this.disableNavButton = true;
    this.currentPeriod = period;
    this.reRenderKey += 1;
    // sets back the chartcount to 1
    this.selectedPeriodCount = 1;
    for (let chartIndex = 3; chartIndex >= this.selectedPeriodCount; chartIndex--) {
      this.chartVisibleArray[chartIndex] = false;
    }
    this.lynusChartInstances.map((c) => c.renderChart());
  }
  /**
   * Swaps period of chart in Fullscreen Mode
   */
  handlePeriodInFullScreenView(period: string) {
    this.currentPeriod = period;
  }

  chartLoaded() {
    this.disableNavButton = false;
  }

  handleFullScreen() {
    (this.$refs.LynusChartBasicView as any).handleFullScreen();
  }

  handleDataToExport(dataToExport: any) {
    this.dataToExport = dataToExport;
  }

  /**
   * Changes stackingoptions of Columncharts
   */
  handleStackingOptions() {
    (this.$refs.LynusChartBasicView as any).toggleStacking();

    this.isStackingButtonActive = false;
    setTimeout(() => {
      this.isStackingButtonActive = true;
    }, 2000);
  }

  async handleCalendar(date: number[]) {
    this.disableNavButton = true;
    (this.$refs.calendarDialog as any).closeDialog();
    this.selectedPeriodCount = date.length;
    this.seriesSuffixes = [];
    const promises = [];

    // loop to load the charts with the needed Period selected from the date-array
    for (let chartIndex = 0; chartIndex < this.selectedPeriodCount; chartIndex++) {
      this.chartVisibleArray[chartIndex] = true;
      promises.push(this.lynusChartInstances[chartIndex].handlePeriod(date[chartIndex]));
    }
    // waits for all the charts to be loaded to avoid Chartstats and Export to be loaded without every data being loaded from the backend
    await Promise.all(promises).then(() => {});
    for (let chartIndex = 0; chartIndex < this.selectedPeriodCount; chartIndex++) {
      const currentChartBounds = this.lynusChartInstances[chartIndex]?.chart.chartBounds;
      // creates Suffixes containing the converted date for the data export
      this.seriesSuffixes.push(this.convertTimestampChartStats(currentChartBounds));
    }
    // loop is used to hide all the charts without a period selected
    for (let chartIndex = 3; chartIndex >= this.selectedPeriodCount; chartIndex--) {
      this.chartVisibleArray[chartIndex] = false;
    }
    this.disableNavButton = false;

    // sets up needed Data for data export
    this.getLegendItems();
    this.generateChartData();

    // generateObject for Total Sum (currently only for Difference Series)
    this.defineSeriesStatsMultiSelection();
  }

  /**
   * Generates array for dataExport Information
   */
  getLegendItems() {
    const itemsList: any = [];
    this.lynusChartInstances.forEach((chartElement: any) => {
      itemsList.push(chartElement.chart.chart.legend.allItems);
    });
    this.localLegendItems = itemsList;
  }

  /**
   * Generates array with all the given data in the charts. In order to display them inside the Export Data file
   */
  generateChartData() {
    const chartData: any[] = [];
    this.dataToExport = undefined;
    for (let chartIndex = 0; chartIndex < this.selectedPeriodCount; chartIndex++) {
      chartData.push(this.lynusChartInstances[chartIndex].chart.dataToExport);
    }
    this.dataToExport = chartData;
  }

  handlePeriodsForAutarkiegrad(period: string) {
    const periodForAutarkiegrad = this.periodConfigurations[period].period();
    this.$emit('handleAutarkiegrad', periodForAutarkiegrad);
  }

  get periodConfigurations() {
    const periodConfigs: any = _.cloneDeep(periodConfigurations); // copy default periodConfigurations
    periodConfigs.day.interval = '15m';
    return periodConfigs;
  }
}
